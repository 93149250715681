import React from 'react';
import { Link } from "gatsby"
import styles from './Footer.module.scss';

const Footer = ({ settings }) => (
  <footer className={styles.footer}>
    <div className={styles.top}>
      <div className={'wrap'}>
        <h4 style={{ color: 'white' }}>Contactgegevens:</h4>
        <div className={'row'}>
          <div className={styles.columns}>
            {settings.adress && settings.openingHours && (
                <div className={'footer__adress'}>
                  <p>{settings.adress}</p>
                  <p>{settings.openingHours}</p>
                </div>
            )}
            {settings.email && settings.phoneNumber && (
                <div className={styles.redirect}>
                  <p>{settings.email}</p>
                  <p>{settings.phoneNumber}</p>
                </div>
            )}
            {settings.socialFacebookUrl && settings.socialInstagramUrl && (
                <div className={'footer__socials'}>
                  <ul className="socials">
                    <li className="socials__item">
                      <a href={settings.socialInstagramUrl} className="socials__link socials__link--insta"></a>
                    </li>
                    <li className="socials__item">
                      <a href={settings.socialFacebookUrl} className="socials__link socials__link--facebook"></a>
                    </li>
                  </ul>
                </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className={styles.bottom}>
      <div className="wrap">
        <div className={styles.wrapper}>
          <Link to={'/privacy-verklaring'}>
            <p className="footer__redirect">privacy verklaring</p>
          </Link>
          <Link to={'/algemene-voorwaarden'}>
            <p className="footer__redirect">algemene voorwaarden</p>
          </Link>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;