import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/layout';

const query = graphql`
  query SiteTitleQuery {
    globalSettings: sanitySiteSettings {
      adress
      alertContext
      alertType
      cookieContext
      email
      openingHours
      phoneNumber
      showAlert
      socialFacebookUrl
      socialInstagramUrl
    },
  }
`

function LayoutContainer (props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Layout
            {...props}
            settings={data.globalSettings}
          />
        )
      }}
    />
  )
}

export default LayoutContainer;