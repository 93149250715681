import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CookieConsent from 'react-cookie-consent';
import logo from "../images/Antje-Machen-Logo.svg";
import Footer from "../components/layout/Footer/Footer";

import "../pages/index.scss";
import "./layout.scss";

const Layout = ({ children, settings }) => {
  const [navActive, setNavActive] = React.useState(false);
  const [navSticky, setNavSticky] = React.useState(false);
  const [startScrollPosition, setStartScrollPosition] = React.useState();

  const navClickHandler = () => {
    if (navActive) {
      setNavActive(false);
      document.body.classList.remove('no-scroll');
    } else {
      setNavActive(true)
      document.body.classList.add('no-scroll');
    }
  }

  const navItemClickHandler = React.useCallback(() => {
    if (navActive) {
      setNavActive(true)
      document.body.classList.add('no-scroll');
    } else {
      setNavActive(false)
      document.body.classList.remove('no-scroll');
    }
  }, [setNavActive])

  const handleNavigationScroll = (e) => {
    const window = e.currentTarget;

    if (window.scrollY > 80) {
      setNavSticky(true);
      return;
    }

    setNavSticky(false);
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setStartScrollPosition(window.scrollY);
      window.addEventListener('scroll', (e) => handleNavigationScroll(e))
    }
  })

  return (
    <>
      <nav className={`nav ${navActive ? "nav--active" : ""} ${navSticky ? "nav--sticky" : ""}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2">
              <div className="nav__item nav__item--branding">
                <Link to='/'>
                  <img alt="Antje Machen" className="nav__logo" src={logo}/>
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-lg-10">
              <div
                href="/"
                class="nav__toggle"
                onClick={navClickHandler}
                title="Navigation"
              >
                <div class="nav__toggle-wrapper">
                  <div class="nav__hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              <ul className="nav__list">
                <li className="nav__item nav__item--link">
                  <Link
                    onClick={navItemClickHandler}
                    activeClassName={"active"}
                    to="/zwangerconnect"
                  >
                    Zwangerconnect
                  </Link>
                </li>
                <li className="nav__item nav__item--link">
                  <Link
                    onClick={navItemClickHandler}
                    activeClassName={"active"}
                    to="/babyconnect"
                  >
                    Babyconnect
                  </Link>
                </li>
                <li className="nav__item nav__item--link">
                  <Link
                    onClick={navItemClickHandler}
                    activeClassName={"active"}
                    to="/over-mij"
                  >
                    Over mij
                  </Link>
                </li>
                <li className="nav__item nav__item--link">
                  <Link
                    onClick={navItemClickHandler}
                    activeClassName={"active"}
                    to="/blogs"
                  >
                    Blogs
                  </Link>
                </li>
                <li className="nav__item nav__item--link">
                  <Link
                    onClick={navItemClickHandler}
                    activeClassName={"active"}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <main className="main">{children}</main>

      <Footer settings={settings} />
      {settings.cookieContext && (
        <CookieConsent
          enableDeclineButton
          disableButtonStyles
          buttonText={"Akkoord"}
          contentStyle={{ flex: "unset" }}
          declineButtonText="Weigeren"
          declineButtonClasses={"button button--secondary button__cookie button__cookie--decline"}
          buttonClasses={"button button__cookie button__cookie--submit"}
          style={{
            background: "#FFF",
            color: "#000",
            padding: "4rem 15%",
            boxShadow: "0 0 1rem 0 rgba(50,50,50,0.25)",
          }}
        >
          {settings.cookieContext}
          <br />
          <br />
          <Link
            to={"/privacy-verklaring"}
            style={{ textDecoration: "underline" }}
          >
            Meer informatie.
          </Link>
        </CookieConsent>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
